export const imageService = (
  image: string,
  props: { focus?: string; size?: string }
) => {
  const originalBase = 'https://a-us.storyblok.com';

  // no image
  if (!image) return;

  // confirm it's a valid SB image
  if (!image.startsWith(originalBase)) return image;

  // At minimum add a `/m/' parameter which returns an optimized web for browsers that support that
  const paramsArray = ['m'];

  // image size (should be in the format the SB image service expects, eg '100x50')
  if (props.size) paramsArray.push(props.size);

  // image filters, only supporting custom focal point right now
  let filters = '';
  const filtersArray = [];
  if (props.focus) filtersArray.push(`focal(${props.focus})`);
  if (filtersArray.length) {
    filters = 'filters:' + filtersArray.join(':');
    paramsArray.push(filters);
  }

  return `${image}/${paramsArray.join('/')}/`;
};
